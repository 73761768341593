'use client';

import { IServiceComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { SyntheticEvent } from 'react';
import Button, { ButtonVariants } from '../../components/Button';
import contactModalTriggerButton from '../../store/contactForm';
import { theme, Theme } from '../../store/theme';
import styles from './Service.module.scss';
import SubserviceCard from './SubserviceCard';
import { GAEmit, GAEvents } from '../../gtagHelper';

const ServicesSection = ({ data }: { data: IServiceComponent }) => {
  const appTheme = useAtomValue<Theme>(theme);
  const splitArrayIndex = data.subservices.length % 2 > 0 ? Math.ceil(data.subservices.length / 2) : data.subservices.length / 2 + 1;
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const handleWorkTogetherClick = (event: SyntheticEvent) => {
    setContactsModalTrigger(`${(event.target as HTMLElement).innerText} | Industry services section`);
    GAEmit(GAEvents.letsWorkTogetherClick);
  };

  return (
    <section className={classnames(styles.services, 'container')}>
      <div className={styles.services__column}>
        {data.subservices.slice(0, splitArrayIndex).map((section, index) => (
          <SubserviceCard
            key={section.id}
            icon={`/media/industry_geometry/Geometry_${(index + 1) % 5 || 5}_${appTheme}.svg`}
            title={section.title}
            text={section.description}
            id={section.id}
            slug={section.slug}
          />
        ))}
      </div>
      <div className={styles.services__column}>
        {data.subservices.slice(splitArrayIndex).map((section, index) => (
          <SubserviceCard
            key={section.id}
            // this index is to make visuals appear repeatedly since we have only 5 of them
            // eslint-disable-next-line
            icon={`/media/industry_geometry/Geometry_${(index + data.subservices.slice(0, splitArrayIndex).length + 1) % 5 || 5}_${appTheme}.svg`}
            title={section.title}
            text={section.description}
            id={section.id}
            slug={section.slug}
          />
        ))}
        <div className={styles.joinUs}>
          <div className={styles.joinUs__overlay} />
          <div className={styles.joinUs__content}>
            <p className={styles.joinUs__title}>Join Us Now!</p>
            <p className={styles.joinUs__text}>
              Talk to our experts to get an estimate and build a market-winning solution for your
              business.
            </p>
            <Button
              variant={ButtonVariants.OUTLINED}
              className={styles.joinUs__button}
              icon={(
                <svg>
                  <use
                    xlinkHref='/media/icon-nest.svg#iconNest'
                    href='/media/icon-nest.svg#iconNest'
                  />
                </svg>
              )}
              iconPosition='right'
              iconSize={{ width: 24, height: 24 }}
              size='normal'
              onClick={handleWorkTogetherClick}
            >
              Let’s work together
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
