'use client';

import { ITechStack, ITechStackComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import Img from '../../../components/Img';
import activeHomeSection, { Section } from '../../../store/activeHomeSection';
import deviceScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';
import { theme } from '../../../store/theme';
import homepageStyles from '../Home.module.scss';

import styles from './TechStack.module.scss';

type TechStackProps = {
  data: ITechStackComponent,
  // contentRef: React.RefObject<HTMLDivElement>,
  id: string,
  contentId: string,
  className?: string,
  type?: 'homepage',
};

const TechStackColumn = ({
  techStack,
  handleColumnOpen,
  isNoActive,
  isActive,
}: {
  techStack: ITechStack;
  handleColumnOpen: (id: number) => void;
  isNoActive: boolean;
  isActive: boolean;
}) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);

  const toggleDropdown = () => {
    if (deviceSize <= ScreenSize.TABLET_PORTRAIT) {
      handleColumnOpen(techStack.id);
    }
  };

  return (
    <div
      className={styles.column}
      key={techStack.id}
    >
      {deviceSize > ScreenSize.TABLET_PORTRAIT
        ? <h3 className={styles.column__title}>{techStack.techStack}</h3>
        : (
          <button
            type='button'
            onClick={toggleDropdown}
            className={classnames(styles.column__title, {
              [styles.active]: isActive,
              [styles.allNotActive]: isNoActive,
            })}
          >
            {techStack.techStack}
            <svg className={styles.dropdownIcon}>
              <use
                xlinkHref='/media/angle_down.svg#angleDown'
                href='/media/angle_down.svg#angleDown'
              />
            </svg>
          </button>
        )}
      <div className={styles.column__content}>
        <div className={classnames(styles.column__technologies, {
          [styles.active]: isActive,
        })}
        >
          {techStack?.technologies?.map(technology => (
            <div
              className={styles.item}
              key={technology.id}
            >
              <div className={styles.item__image}>
                <img
                  height={32}
                  width={32}
                  src={technology?.image?.[appTheme]?.url}
                  alt={technology?.image?.[appTheme]?.alternativeText}
                  loading='lazy'
                />
              </div>
              <div className={styles.item__titleWrap}>
                <p className={styles.item__title}>{technology?.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TechStack = ({
  data, contentId, className, type, id,
}: TechStackProps) => {
  const [isAnimationStarted, setIsAnimationStarted] = useState<boolean>(false);
  const [techStackImageContainerOffsetTop, setTechStackImageContainerOffsetTop] = useState<number>(0);
  const [activeColumns, setActiveColumns] = useState<number[]>([data?.tech_stacks?.[0]?.id]);
  const [isNoActive, setIsNoActive] = useState<boolean>(false);
  const activeSection = useAtomValue(activeHomeSection);
  const deviceSize = useAtomValue(deviceScreenSize);

  useEffect(() => {
    setIsAnimationStarted(activeSection === Section.THIRD);
    if (typeof window !== undefined) {
      setTechStackImageContainerOffsetTop((document?.getElementById('techStackContentId')?.offsetTop || 0) - 180);
    }
  }, [activeSection]);

  const handleColumnOpen = (columnId: number) => {
    const isOpen = !!activeColumns.find(currentId => columnId === currentId);
    if (isOpen) {
      setActiveColumns(prev => prev.filter(currentId => currentId !== columnId));
    } else {
      setActiveColumns([...activeColumns, columnId]);
    }
  };

  useEffect(() => {
    if (activeColumns.length) {
      setIsNoActive(false);
    } else {
      setIsNoActive(true);
    }
  }, [activeColumns]);

  return (
    <section
      // ref={ref}
      id={id}
      className={className}
    >
      <div className={classnames(styles.techStack, {
        [styles.techStack_homepage]: type === 'homepage',
      })}
      >
        <div className='container'>
          <div className={styles.techStack__content}>
            <div
              className={classnames(homepageStyles.sidebar__visual2, {
                [homepageStyles.visible]: activeSection === Section.THIRD,
              })}
              style={{
                top: techStackImageContainerOffsetTop,
              }}
            >
              <Img
                src='/media/Home/sidebar/techStackLines.webp'
                alt='lines'
              />
            </div>
            <header className={styles.techStack__header}>
              <h2 className={classnames('sectionTitle', styles.title)}>
                <AnimatedTitle title={data?.title} />
              </h2>
              <p className={styles.techStack__text}>
                {data?.content}
              </p>
            </header>

            <div className={styles.content__wrap}>
              {deviceSize > ScreenSize.TABLET_PORTRAIT && (
                <div className={classnames(styles.techStack__line, {
                  [styles.techStack__line_animate]: isAnimationStarted,
                })}
                />
              )}
              <div
                className={styles.content}
                // ref={contentRef}
                id={contentId}
              >
                {data?.tech_stacks?.map(techStack => (
                  <React.Fragment key={techStack.id}>
                    <TechStackColumn
                      techStack={techStack}
                      handleColumnOpen={handleColumnOpen}
                      isNoActive={isNoActive}
                      isActive={activeColumns.includes(techStack.id)}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechStack;
